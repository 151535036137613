<template>
    <div>
        <NavOnlineAdmission></NavOnlineAdmission>
        <b-container>
            <b-row class="mt-5 justify-content-center">
                <div class="col-md-4 col-12" :sub-title="form.class" v-for="form in forms" :key="form.id">
                    <b-card :title="form.title">
                        <b-card-text>
                            {{ form.description }}
                        </b-card-text>
                        <b-card-text>
                            <b>Start Date:</b> {{ form.start_date }}
                        </b-card-text>
                        <b-card-text>
                            <b>End Date:</b> {{ form.end_date }}
                        </b-card-text>
                        <router-link :to="{ name: 'form', params: { institute: institute, formId: form.adm_admission_id } }">
                            <b-button variant="primary">Online Admission</b-button>
                        </router-link>
                    </b-card>
                </div>

            </b-row>
        </b-container>
    </div>
</template>
  
<script>
import {
    BCard, BCardText, BButton, BContainer, BRow, BCol
} from 'bootstrap-vue'

import NavOnlineAdmission from '@/views/components/nav/NavOnlineAdmission.vue'
import axios from 'axios';


export default {
    components: {
        BCard,
        BCardText,
        BButton,
        BContainer,
        BRow,
        BCol,
        NavOnlineAdmission
    },
    data() {
        return {
            forms: [],
            institute: this.$route.params.institute
        }
    },
    directives: {

    },
    props: {

    },

    created() {
        this.getapplicantDetailsdata();
    },

    methods: {
        getapplicantDetailsdata() {
            let institute = this.institute;
            axios.get(process.env.VUE_APP_API_URL + `get-form-list/${institute}`)
                .then(response => {
                    this.forms = response.data.data
                })
                .catch(error => {
                    // console.log(error);
                });
        },
    }
}
</script>
<style scoped></style>
  